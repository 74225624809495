<template>
  <div class="index-wrapper">
    <div class="header-item">
      <h3>欢迎使用食安追溯工具</h3>
      <p>请选择您需要操作的功能</p>
    </div>

    <ul class="menu-items">
      <li @click="goMarkets">
        <div class="item-main">
          <h3>进场登记&商品上架</h3>
          <p>可通过此功能进行进场登记及商品上架</p>
        </div>
        <div class="icon">
          <i></i>
        </div>
      </li>
      <li class="food-item" @click="goRestaurants">
        <div class="item-main">
          <h3>食材录入</h3>
          <p>可通过此功能对食材进行录入，以完成食材信息追溯</p>
        </div>
        <div class="icon">
          <i></i>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {}
  },
  mounted () {
  },
  methods: {
    goMarkets () {
      this.$router.push({
        name: 'Markets'
      })
    },
    goRestaurants () {
      this.$router.push({
        name: 'Restaurants'
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
